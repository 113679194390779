var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "feedbackType",
    },
    [
      _vm.channelList.length
        ? _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.channelNo,
                callback: function ($$v) {
                  _vm.channelNo = $$v
                },
                expression: "channelNo",
              },
            },
            [
              _vm._l(_vm.channelList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.channelName, name: item.channelNo },
                })
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.contentLoading,
                      expression: "contentLoading",
                    },
                  ],
                  staticClass: "feedbackType-content",
                },
                [
                  _vm.isShowContent
                    ? _c(
                        "div",
                        { staticClass: "tree" },
                        [
                          _c("el-tree", {
                            ref: "typeTree",
                            attrs: {
                              load: _vm.loadNode,
                              "node-key": "id",
                              lazy: "",
                              "expand-on-click-node": false,
                              "default-expanded-keys": [-1],
                              props: {
                                label: "typeName",
                                children: "children",
                                isLeaf: "isLeaf",
                              },
                            },
                            on: { "node-click": _vm.nodeClick },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ node }) {
                                    return _c(
                                      "span",
                                      {
                                        class: [
                                          "custom-tree-node",
                                          _vm.activeNode === node
                                            ? "active"
                                            : "",
                                        ],
                                      },
                                      [
                                        _c("span", { staticClass: "label" }, [
                                          _vm._v(_vm._s(node.label)),
                                        ]),
                                        _vm.activeNode === node
                                          ? _c(
                                              "span",
                                              [
                                                _vm.activeNode.level <= 2 &&
                                                node.data.canEdit == 0
                                                  ? _c(
                                                      "el-button",
                                                      {
                                                        staticClass: "addBtn",
                                                        attrs: {
                                                          size: "mini",
                                                          type: "text",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.append(
                                                              node,
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "el-icon-plus",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.activeNode.level > 1 &&
                                                (!node.data.alias ||
                                                  node.data.alias == "xcyw")
                                                  ? _c(
                                                      "el-dropdown",
                                                      {
                                                        attrs: {
                                                          size: "small",
                                                          placement: "bottom",
                                                        },
                                                        on: {
                                                          command:
                                                            _vm.handleCommand,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticClass: "more",
                                                            attrs: {
                                                              size: "mini",
                                                              type: "text",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "el-icon-more",
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-dropdown-menu",
                                                          {
                                                            staticStyle: {
                                                              padding: "0",
                                                            },
                                                            attrs: {
                                                              slot: "dropdown",
                                                            },
                                                            slot: "dropdown",
                                                          },
                                                          [
                                                            _c(
                                                              "el-dropdown-item",
                                                              {
                                                                attrs: {
                                                                  command: {
                                                                    method:
                                                                      "edit",
                                                                    node,
                                                                  },
                                                                },
                                                              },
                                                              [_vm._v("编辑")]
                                                            ),
                                                            node.data.alias !=
                                                            "xcyw"
                                                              ? _c(
                                                                  "el-dropdown-item",
                                                                  {
                                                                    attrs: {
                                                                      command: {
                                                                        method:
                                                                          "remove",
                                                                        node,
                                                                      },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "删除"
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              2234044825
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowContent
                    ? _c("div", { staticClass: "operation" }, [
                        _c("div", { staticClass: "head" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formState == 0
                                  ? "查看："
                                  : _vm.formState == 1
                                  ? "编辑："
                                  : ""
                              ) +
                              _vm._s(_vm.headInfo) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "form",
                                staticClass: "type-form",
                                attrs: {
                                  model: _vm.form,
                                  "label-position": "right",
                                  "label-width": "85px",
                                  "hide-required-asterisk": true,
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "类型名称：",
                                      prop: "typeName",
                                      rules: {
                                        required: true,
                                        message: "请输入类型名称",
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        clearable: "",
                                        disabled:
                                          _vm.formState == 0 ||
                                          _vm.activeNode.data.alias == "xcyw",
                                      },
                                      model: {
                                        value: _vm.form.typeName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "typeName", $$v)
                                        },
                                        expression: "form.typeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "上级节点：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          disabled:
                                            _vm.formState == 0 ||
                                            _vm.activeNode.level <= 2,
                                        },
                                        model: {
                                          value: _vm.form.supDep,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "supDep", $$v)
                                          },
                                          expression: "form.supDep",
                                        },
                                      },
                                      _vm._l(_vm.supList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            value: item.id,
                                            label: item.typeName,
                                            disabled:
                                              (_vm.activeNode &&
                                                item.id ===
                                                  _vm.activeNode.data.id) ||
                                              item.id === -1,
                                            clearable: "",
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.activeNode && _vm.activeNode.level == 2
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "节点描述：" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            disabled: _vm.formState == 0,
                                          },
                                          model: {
                                            value: _vm.form.describe,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "describe",
                                                $$v
                                              )
                                            },
                                            expression: "form.describe",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.activeNode && _vm.activeNode.level == 2
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { label: "视频附件：" } },
                                      [
                                        _c("el-switch", {
                                          attrs: {
                                            disabled: _vm.formState == 0,
                                            "active-value": 1,
                                            "inactive-value": 0,
                                            "active-color": "#336FFE",
                                            "inactive-color": "#cccccc",
                                          },
                                          model: {
                                            value: _vm.form.videoAnnexStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "videoAnnexStatus",
                                                $$v
                                              )
                                            },
                                            expression: "form.videoAnnexStatus",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: 0,
                                                  disabled: _vm.formState == 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.videoAnnexType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "videoAnnexType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.videoAnnexType",
                                                },
                                              },
                                              [_vm._v("在线上传")]
                                            ),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: 1,
                                                  disabled: _vm.formState == 0,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form.videoAnnexType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "videoAnnexType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.videoAnnexType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                    },
                                                  },
                                                  [_vm._v("邮箱")]
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.formState == 0,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.videoAnnexEmail,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "videoAnnexEmail",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.videoAnnexEmail",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "启用节点：" } },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        disabled: _vm.formState == 0,
                                        "active-color": "#336FFE",
                                        "inactive-color": "#cccccc",
                                      },
                                      model: {
                                        value: _vm.form.status,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "status", $$v)
                                        },
                                        expression: "form.status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.formState == 1
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              size: "small",
                                            },
                                            on: { click: _vm.saveEdit },
                                          },
                                          [_vm._v("保存")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            2
          )
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }