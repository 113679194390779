<template>
  <!-- 反馈类型 -->
  <div v-loading="pageLoading" class="feedbackType">
    <el-tabs
      v-if="channelList.length"
      v-model="channelNo"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in channelList"
        :key="index"
        :label="item.channelName"
        :name="item.channelNo"
      >
      </el-tab-pane>
      <div v-loading="contentLoading" class="feedbackType-content">
        <div v-if="isShowContent" class="tree">
          <el-tree
            ref="typeTree"
            :load="loadNode"
            node-key="id"
            lazy
            :expand-on-click-node="false"
            :default-expanded-keys="[-1]"
            :props="{
              label: 'typeName',
              children: 'children',
              isLeaf: 'isLeaf',
            }"
            @node-click="nodeClick"
          >
            <span
              slot-scope="{ node }"
              :class="['custom-tree-node', activeNode === node ? 'active' : '']"
            >
              <span class="label">{{ node.label }}</span>
              <span v-if="activeNode === node">
                <el-button
                  v-if="activeNode.level <= 2 && node.data.canEdit == 0"
                  size="mini"
                  type="text"
                  class="addBtn"
                  @click="append(node, $event)"
                >
                  <span class="el-icon-plus" />
                </el-button>
                <el-dropdown
                  v-if="
                    activeNode.level > 1 &&
                    (!node.data.alias || node.data.alias == 'xcyw')
                  "
                  size="small"
                  placement="bottom"
                  @command="handleCommand"
                >
                  <el-button size="mini" type="text" class="more">
                    <span class="el-icon-more" />
                  </el-button>
                  <el-dropdown-menu slot="dropdown" style="padding: 0">
                    <el-dropdown-item :command="{ method: 'edit', node }"
                      >编辑</el-dropdown-item
                    >
                    <el-dropdown-item
                      v-if="node.data.alias != 'xcyw'"
                      :command="{ method: 'remove', node }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </span>
          </el-tree>
        </div>
        <div v-if="isShowContent" class="operation">
          <div class="head">
            {{ formState == 0 ? "查看：" : formState == 1 ? "编辑：" : ""
            }}{{ headInfo }}
          </div>
          <div class="right">
            <el-form
              ref="form"
              :model="form"
              class="type-form"
              label-position="right"
              label-width="85px"
              :hide-required-asterisk="true"
            >
              <el-form-item
                label="类型名称："
                prop="typeName"
                :rules="{
                  required: true,
                  message: '请输入类型名称',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="form.typeName"
                  clearable
                  :disabled="formState == 0 || activeNode.data.alias == 'xcyw'"
                />
              </el-form-item>
              <el-form-item label="上级节点：">
                <el-select
                  v-model="form.supDep"
                  style="width: 100%"
                  :disabled="formState == 0 || activeNode.level <= 2"
                >
                  <el-option
                    v-for="item in supList"
                    :key="item.id"
                    :value="item.id"
                    :label="item.typeName"
                    :disabled="
                      (activeNode && item.id === activeNode.data.id) ||
                      item.id === -1
                    "
                    clearable
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="activeNode && activeNode.level == 2"
                label="节点描述："
              >
                <el-input
                  v-model="form.describe"
                  clearable
                  :disabled="formState == 0"
                />
              </el-form-item>
              <el-form-item
                v-if="activeNode && activeNode.level == 2"
                label="视频附件："
              >
                <el-switch
                  v-model="form.videoAnnexStatus"
                  :disabled="formState == 0"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#336FFE"
                  inactive-color="#cccccc"
                />
                <div>
                  <el-radio
                    v-model="form.videoAnnexType"
                    :label="0"
                    :disabled="formState == 0"
                    >在线上传</el-radio
                  >
                  <el-radio
                    v-model="form.videoAnnexType"
                    :label="1"
                    :disabled="formState == 0"
                  >
                    <span style="margin-right: 10px">邮箱</span>
                    <el-input
                      v-model="form.videoAnnexEmail"
                      :disabled="formState == 0"
                    ></el-input>
                  </el-radio>
                </div>
              </el-form-item>
              <el-form-item label="启用节点：">
                <el-switch
                  v-model="form.status"
                  :disabled="formState == 0"
                  active-color="#336FFE"
                  inactive-color="#cccccc"
                />
              </el-form-item>
              <el-form-item v-if="formState == 1">
                <el-button type="primary" size="small" @click="saveEdit"
                  >保存</el-button
                >
                <!-- <el-button type="normal" size="small" @click="resetInfo"
                  >重置</el-button
                > -->
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </el-tabs>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
import {
  feedbackTypeListAPI,
  feedbackTypeEditAPI,
  feedbackTypeDeleteAPI,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelNo: null,
      channelList: [],
      isShowContent: false,
      contentLoading: false,
      data: [
        {
          typeName: "反馈类型",
          id: -1,
          status: 0,
          canEdit: 0,
        },
      ],
      id: 100,
      form: {
        typeName: "",
        supDep: "",
        status: true,
        describe: "",
        videoAnnexStatus: 0, //是否开启视频附件 0 关闭 1开启
        videoAnnexType: null, //视频附件类型 0在线上传 1 邮箱上传
        videoAnnexEmail: "", //视频附件邮箱地址
      },
      supList: [],
      activeNode: null,
      formState: 0,
    };
  },
  computed: {
    headInfo() {
      // 面包屑
      return this.getLabels(this.activeNode);
    },
  },
  created() {
    this.getQueryChannelList();
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      this.contentLoading = true;
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data;
            this.channelNo = res.data[0]?.channelNo;
            this.contentLoading = false;
            this.isShowContent = true;
            if (!res.data.length) {
              this.isShowContent = false;
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
            this.pageLoading = false;
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    //点击渠道tab项
    handleClick(val) {
      this.isShowContent = false;
      this.contentLoading = true;
      this.formState = 0;
      this.activeNode = null;
      this.form = this.$options.data().form;
      setTimeout(() => {
        this.isShowContent = true;
        this.contentLoading = false;
      }, 1000);
    },
    // 懒加载树节点
    loadNode(node, resolve) {
      if (node.level == 0) {
        return resolve(this.data);
      }
      let id = node.data.id === -1 ? null : node.data.id;
      feedbackTypeListAPI({ id, channelNo: this.channelNo }).then((res) => {
        if (res.data) {
          if (node.level == 2) {
            res.data.forEach((item) => {
              item.isLeaf = true;
            });
          }
          resolve(res.data);
        } else {
          resolve([]);
        }
      });
    },
    // 从叶子节点向上逐级获取label
    getLabels(node) {
      if (node) {
        if (node.parent && node.parent.label) {
          return this.getLabels(node.parent) + "/" + node.label;
        } else {
          return node.label;
        }
      } else {
        return "";
      }
    },
    //添加子节点
    append(node, e) {
      console.log(node);
      e.stopPropagation();
      feedbackTypeEditAPI({
        id: null,
        typeName: "新建节点",
        parentName: node.label,
        parentId: node.data.id == -1 ? null : node.data.id,
        channelNo: this.channelNo,
      }).then((res) => {
        node.loaded = false;
        node.loadData();
      });
    },
    // 点击下拉菜单回调
    handleCommand(com) {
      switch (com.method) {
        case "edit":
          this.editNode(com.node);
          break;
        case "remove":
          this.remove(com.node);
        default:
          break;
      }
    },
    //删除
    remove(node) {
      this.$confirm("确定删除该类型？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        feedbackTypeDeleteAPI({ id: node.data.id }).then((res) => {
          this.$message.success("删除成功！");
          node.parent.loaded = false;
          node.parent.loadData();
        });
      });
    },
    // 编辑节点
    editNode(node) {
      this.editTarget = node;
      this.formState = 1;
      this.form.typeName = node.label;
      this.form.status = node.data.status === 0;
      if (node.parent.data) {
        this.form.supDep = node.parent.data.id;
      } else {
        this.form.supDep = "";
      }
    },
    // 重置
    resetInfo() {
      console.log("reset");
      this.form.status = false;
      this.form.typeName = "";
      this.form.supDep = "";
    },
    //保存编辑
    saveEdit() {
      let node = this.editTarget;
      let parent = this.$refs.typeTree.getNode(this.form.supDep);
      if (!parent) parent = this.$refs.typeTree.getNode(-1);
      this.$refs.form.validate((valid) => {
        if (valid) {
          let regEmail =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            this.form.videoAnnexStatus == 1 &&
            this.form.videoAnnexType == null
          ) {
            this.$message.error("请选择视频附件类型！");
            return;
          } else if (
            this.form.videoAnnexType == 1 &&
            !this.form.videoAnnexEmail
          ) {
            this.$message.error("请填写邮箱地址！");
            return;
          } else if (
            this.form.videoAnnexType == 1 &&
            !regEmail.test(this.form.videoAnnexEmail)
          ) {
            this.$message.error("请填写正确的邮箱地址！");
            return;
          }
          let params = {
            id: node.data.id,
            typeName: this.form.typeName,
            status: this.form.status ? 0 : 1,
            parentId: parent.data.id == -1 ? null : parent.data.id,
            parentName: parent.data.id == -1 ? null : parent.label,
            channelNo: this.channelNo,
          };
          if (this.activeNode.level == 2) {
            params.describe = this.form.describe;
            params.videoAnnexStatus = this.form.videoAnnexStatus;
            params.videoAnnexType = this.form.videoAnnexType;
            params.videoAnnexEmail = this.form.videoAnnexEmail;
          }
          feedbackTypeEditAPI(params).then((res) => {
            this.$message.success("保存成功！");
            parent.loaded = false;
            parent.loadData();
            if (parent !== node.parent) {
              node.parent.loaded = false;
              node.parent.loadData();
            }
          });
        }
      });
    },
    nodeClick(data, node, ref) {
      if (node === this.activeNode) return;
      this.activeNode = node;
      this.formState = 0;
      this.form.typeName = node.label;
      this.form.status = node.data.status === 0;
      if (node.level == 2) {
        this.form.describe = node.data.describe;
        this.form.videoAnnexStatus = node.data.videoAnnexStatus;
        this.form.videoAnnexType = node.data.videoAnnexType;
        this.form.videoAnnexEmail = node.data.videoAnnexEmail;
      }
      if (node.parent.parent) {
        this.supList = this.getAllParents(node.parent);
        this.$nextTick(() => {
          if (node.parent.data) {
            this.form.supDep = node.parent.data.id;
          } else {
            this.form.supDep = "";
          }
        });
      }
    },
    // 拿到所有的父级
    getAllParents(parent) {
      let arr = [];
      function pushParent(parent) {
        if (parent.parent) {
          parent.parent.childNodes.forEach((item) => {
            arr.push(item.data);
          });
          pushParent(parent.parent);
        }
      }
      pushParent(parent);
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.feedbackType {
  height: 100%;
  width: 100%;
  display: flex;
  .el-tabs {
    width: 100%;
    ::v-deep .el-tabs__header {
      background-color: #fff;
    }
    ::v-deep .el-tabs__content {
      height: 100%;
    }
    .feedbackType-content {
      display: flex;
      height: 100%;
    }
  }
  .tree {
    overflow-y: auto;
    flex-shrink: 0;
    width: 300px;
    height: 100%;
    padding: 20px 10px;
    background-color: #fff;
  }
  .operation {
    flex: 1;
    margin-left: 20px;
    background-color: #fff;
    .head {
      padding: 17px 0 15px 20px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      border-bottom: 1px solid #ccc;
    }
    .right {
      width: 600px;
    }
  }
  .type-form {
    padding: 20px 60px;
  }
  .custom-tree-node {
    flex: 1;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: flex-start;
    padding-right: 8px;
    .label {
      overflow: hidden; //溢出隐藏
      white-space: nowrap; //禁止换行
      text-overflow: ellipsis; //...
      display: inline-block;
      max-width: 215px;
    }
    .el-button {
      color: #555;
      margin-left: 10px;
      font-size: 12px;
      border-radius: 2px;
      &:hover {
        color: #336ffe;
        border-color: #336ffe;
      }
    }
    .addBtn {
      border: 1px solid #aaa;
      padding: 0px;
    }
    .more {
      transform: rotateZ(90deg);
      border: none;
    }
    &.active {
      .label {
        color: #336ffe;
      }
    }
  }
}
</style>
